.basket-addon-card {
  margin-bottom: 20px;
  position: relative;
}

@media (max-width: 767px) {
  .basket-addon-card {
    max-width: 360px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .basket-addon-card-open-modal {
    min-height: 304px;
  }
}

.basket-addon-card-content {
  text-align: left;
}

@media (min-width: 768px) {
  .basket-addon-card-header-dt {
    height: 66px;
  }
}

.basket-addon-card-name {
  color: #212427;
  letter-spacing: 0;
  text-align: left;
  height: 66px;
  margin-bottom: 10px;
  font-family: Apercu;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}

.basket-addon-card-description {
  color: #212427;
  text-align: left;
  font-family: Apercu;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0 !important;
}

.basket-addon-card-available-variant {
  color: #212427;
  text-align: left;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  font-family: Apercu;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0 !important;
}

.basket-addon-card-origin {
  align-items: normal;
  display: flex;
}

@media (min-width: 576px) {
  .basket-addon-card-origin {
    align-items: center;
  }
}

.basket-addon-card-origin-flag {
  height: 20px;
  width: 27px;
  margin-right: 10px;
}

.basket-addon-card-origin .blend-height {
  width: 15px !important;
}

@media (min-width: 992px) {
  .basket-addon-card-origin .blend-height {
    width: 22px !important;
  }
}

.basket-addon-card-origin span {
  color: #212427;
  margin-top: 2px;
  font-family: Apercu;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 18px;
  letter-spacing: 0 !important;
}

.basket-addon-card-price-cnt {
  margin: 15px 0 20px;
}

@media (min-width: 768px) {
  .basket-addon-card-price-cnt.addon-without-original {
    height: 64px;
    margin-bottom: 0;
  }
}

.basket-addon-card-price {
  color: #212427;
  text-align: center;
  font-family: Apercu;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0 !important;
}

.basket-addon-card-price-variant-indicator {
  color: #212427;
  text-align: center;
  font-family: Apercu;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  position: relative;
  top: -5px;
  letter-spacing: 0 !important;
}

.basket-addon-card-price.pioneer span {
  background-color: #ffd699;
  padding: 6px 6px 2px;
}

.basket-addon-card-price.on-sale {
  color: #d94822;
}

.basket-addon-card-original-price {
  color: #212427;
  text-align: center;
  margin-top: 5px;
  font-family: Apercu;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  letter-spacing: 0 !important;
}

.basket-addon-card-original-price .price-trace {
  -webkit-text-decoration: line-through #212427;
  text-decoration: line-through #212427;
  -webkit-text-decoration-line: line-through #212427;
}

.basket-addon-card-img-cnt {
  height: 187px;
  position: relative;
}

.basket-addon-card-img-cnt .full-width-img {
  width: 100%;
  height: 187px;
  object-fit: cover;
}

@media (max-width: 576px) {
  .basket-addon-card-img-cnt .full-width-img.img-xs-opts {
    height: 167px;
  }
}

.basket-addon-card-img-cnt .thumbnail-img {
  width: 120px;
  object-fit: cover;
  z-index: 1;
  position: relative;
  left: 1px;
}

.basket-addon-card-img-cnt .coffee-label-img {
  height: 100px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 0;
  position: absolute;
  bottom: 0;
}

.basket-addon-card .addon-card-modal {
  z-index: 3;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .85);
  border-radius: 8px;
  padding: 15px;
  position: absolute;
}

.basket-addon-card .addon-card-modal-notification {
  width: 100%;
  text-align: center;
  z-index: 1;
  letter-spacing: 0;
  color: #fff;
  background-color: #eb5757;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
  line-height: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.basket-addon-card .addon-card-modal .addon-card-modal-prod-name {
  color: #fff;
  letter-spacing: 0;
  font-family: Apercu;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
}

.basket-addon-card .addon-card-modal .addon-card-modal-dropdown-title {
  color: #fff;
  letter-spacing: .12px;
  text-transform: uppercase;
  font-family: Apercu;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.basket-addon-card .addon-card-modal .dropdown-parent {
  box-sizing: border-box;
  height: 40px;
  min-width: 220px;
  background: #fff;
  border: 2px solid #e5e5e5;
  border-radius: 5px;
  padding: 4px 15px;
  position: relative;
}

.basket-addon-card .addon-card-modal .dropdown-selected {
  text-align: left;
  color: #1d4045;
  letter-spacing: 0;
  text-transform: capitalize;
  font-family: Apercu;
  font-size: 14px;
  font-weight: 700;
}

.basket-addon-card .addon-card-modal .address-arrow {
  width: 15px;
  max-width: 15px;
  position: absolute;
  top: 50%;
  right: 15px;
}

.basket-addon-card .addon-card-modal .wrap-dropdown-content {
  position: absolute;
  top: 43px;
  left: 0;
  z-index: 30 !important;
}

.basket-addon-card .addon-card-modal .wrap-dropdown-content .dropdown-content {
  max-height: 66px;
  overflow-y: scroll;
}

.basket-addon-card .addon-card-modal .wrap-dropdown-content .dropdown-content ul {
  margin: 0;
}

.basket-addon-card .addon-card-modal .wrap-dropdown-content .dropdown-content ul > li {
  text-align: left;
  color: #1d4045;
  letter-spacing: 0;
  padding: 10px 20px;
  font-size: 14px;
  text-decoration: none;
  display: block;
}

.basket-addon-card .addon-card-modal .wrap-dropdown-content .dropdown-content ul > li:hover {
  cursor: pointer;
  font-family: Apercu-medium;
}

.basket-header {
  color: #1c4245;
  text-transform: capitalize;
  font-family: Apercu;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
}

.basket-item-title {
  margin-right: 35px;
  display: block;
}

.basket-bin {
  float: right;
  position: static;
}

.right-0 {
  right: 0;
}

.prod-image {
  min-height: 100px;
  width: 30%;
  margin-bottom: 0 !important;
}

.basket-quantities {
  width: calc(30% + 86px);
  font-size: 13px;
}

@media (max-width: 991px) {
  .basket-quantities {
    width: calc(30% + 95px);
  }
}

@media (max-width: 575px) {
  .basket-quantities {
    font-size: 16px;
  }
}

.basket-prices {
  width: 100%;
  font-size: .6rem;
  overflow: hidden;
}

.image-dim {
  max-height: 100px;
  max-width: 100px;
}

#basket-flexi-items {
  margin-top: 28%;
  border: none !important;
}

#basket-flexi-items button {
  padding: 0;
}

#basket-flexi-items button p {
  text-transform: none;
  font-size: 14px;
}

#basket-flexi-items button img {
  position: initial;
  width: 13px;
}

#order-summary-flexi-items {
  margin-top: 5%;
  margin-bottom: 5px;
  border: none !important;
}

#order-summary-flexi-items button {
  padding: 0;
}

#order-summary-flexi-items button p {
  text-transform: none;
  font-size: 14px;
}

#order-summary-flexi-items button img {
  position: initial;
  width: 13px;
}

.delivery-info[data-v-413265] {
  background: #f3f3f3;
  padding: 15px 20px;
}

.delivery-info.no-bg[data-v-413265] {
  background-color: rgba(0, 0, 0, 0);
}

.delivery-info img[data-v-413265] {
  width: 36px;
  height: 40px;
}

.delivery-info img.truck-img[data-v-413265] {
  height: 32px;
}

.delivery-info span[data-v-413265] {
  color: #212427;
  margin-bottom: 5px;
  font-family: Apercu-medium;
  font-size: 14px;
  font-weight: 700;
  line-height: 14px;
}

.delivery-info span.sub[data-v-413265] {
  margin-bottom: 0;
  font-family: Apercu;
  font-size: 13px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
}

.delivery-info-free-delivery[data-v-413265] {
  background: rgba(255, 173, 50, .25);
  padding: 20px;
}

.delivery-info-free-delivery.bespoke[data-v-413265] {
  background: none;
  padding: 0 20px;
}

.delivery-info-free-delivery img[data-v-413265] {
  width: 25px;
  height: 18px;
}

.delivery-info-free-delivery span[data-v-413265] {
  color: #1d4045;
  font-family: Apercu;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.espresso-martini-kit span[data-v-413265] {
  color: #fff;
}

.espresso-martini-kit img[data-v-413265] {
  filter: brightness(0) saturate() invert(71%) sepia(16%) saturate(1007%) hue-rotate(2deg) brightness(96%) contrast(87%);
}

@media (max-width: 360px) {
  .bespoke-12 .bespoke span.bespoke[data-v-413265] {
    width: 160px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .bespoke-12 .bespoke span.bespoke[data-v-413265] {
    width: 160px;
  }
}

@media (max-width: 1500px) {
  .bespoke-12 .bespoke[data-v-413265] {
    padding-left: 0;
  }

  .bespoke-12 .bespoke .justify-content-center[data-v-413265] {
    justify-content: left !important;
  }
}

.delivery-field[data-v-837ec4] {
  padding-top: 25px;
}

span.delivery-check[data-v-837ec4] {
  color: #1d4045;
  font-family: Apercu;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
}

span.delivery-check b[data-v-837ec4] {
  font-weight: 700;
}

span.delivery-mark[data-v-837ec4] {
  color: #1d4045;
  font-family: Apercu-medium;
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
}

.delivery-bar[data-v-48b1f3] {
  height: 15px;
  width: 100%;
}

.delivery-bar.main[data-v-48b1f3] {
  z-index: 1;
}

.delivery-bar.inner[data-v-48b1f3] {
  z-index: 2;
}

.delivery-bar.delivery[data-v-48b1f3] {
  border-radius: 100px;
}

.delivery-bar.delivery.main[data-v-48b1f3] {
  background-color: #ebebeb;
}

.delivery-bar.delivery.inner[data-v-48b1f3] {
  background-image: linear-gradient(-45deg, #1d4045 25%, #4a666a 25%, #4a666a 50%, #1d4045 50%, #1d4045 75%, #4a666a 75%, #4a666a);
  background-size: 15px 15px;
  animation: 1s linear infinite animateBar-48b1f3;
}

.delivery-bar.delivery.inner.complete[data-v-48b1f3] {
  background-image: linear-gradient(-45deg, #ffad32 25%, #ffbd5b 25%, #ffbd5b 50%, #ffad32 50%, #ffad32 75%, #ffbd5b 75%, #ffbd5b);
}

@keyframes animateBar-48b1f3 {
  0% {
    background-position: -15px 0;
  }

  100% {
    background-position: 0 0;
  }
}

.checkout-btn-sticky {
  width: 100%;
  z-index: 3;
  background: #fff;
  padding: 0 20px;
  position: sticky;
  bottom: 0;
}

.checkout-btn-sticky .basket-footer {
  width: 100%;
  z-index: 2;
  text-align: center;
  position: relative;
  bottom: 0;
  left: 0;
}

.checkout-btn-sticky .basket-footer .basket-total {
  text-align: right;
  margin-bottom: 30px;
  padding: 20px 0 16px;
}

.checkout-btn-sticky .basket-footer .basket-total span {
  float: left;
  margin-top: 8px;
}

.hide-checkout-btn-sticky {
  display: none;
}

.basket-total-pad[data-v-c95235] {
  padding: 30px;
}

@media (min-width: 0) and (max-width: 420px) {
  .basket-total-pad[data-v-c95235] {
    padding: 15px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .basket-total-pad[data-v-c95235] {
    padding: 15px;
  }
}

.basket-total span[data-v-c95235] {
  font-family: Apercu;
  font-weight: 700;
}

.basket-total .discount[data-v-c95235] {
  font-size: 16px;
  line-height: 20px;
}

.basket-total .discount-value[data-v-c95235] {
  text-align: right;
  font-size: 16px;
  line-height: 16px;
}

.basket-total .total[data-v-c95235] {
  font-size: 20px;
  line-height: 20px;
}

.basket-total .total-value[data-v-c95235] {
  text-align: right;
  font-size: 36px;
  line-height: 36px;
}

.basket-total .total-value.discounted[data-v-c95235] {
  color: #32bea6;
}

.basket-total .text-trace-stl[data-v-c95235] {
  color: #212427;
  letter-spacing: .12px !important;
  font-family: Apercu !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 300 !important;
  line-height: 26px !important;
}

.basket-total .price-trace.neutral-black[data-v-c95235] {
  -webkit-text-decoration: line-through #212427;
  text-decoration: line-through #212427;
  -webkit-text-decoration-line: line-through #212427;
}

.wrap-limit {
  height: 270px !important;
}

.baddons-maintitle {
  font-family: Apercu-Medium;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.hr-baddon {
  border-bottom: 1px solid #c4c4c4;
}

.hr-baddon.two {
  margin-top: -20px;
}

/*# sourceMappingURL=index.css.map */
